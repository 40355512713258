import React from 'react'
import styled from 'styled-components'

import Image from '../assets/not_found.png'
import { Link } from 'react-router-dom'

const Container = styled.div`
    min-height: 100vh;
    background: rgb(0, 28, 36);
`

const Card = styled.div`
    background: white;
    border-radius: 26px;
    box-shadow: rgba(25, 93, 194, 0.07) 0px 2px 10px;
    background: rgb(0, 28, 36);
`

const Heading = styled.h1`
    font-family: 'Inter';
    font-size: 124px;
    font-weight: 600;
    color: #1476FF;
`

const Text = styled.p`
    font-family: 'Inter';
    font-size: 24px;
    color: white;
`

const Button = styled.button`
    border: none;
    background: #1476FF;
    font-style: 'Inter';
    font-weight: 700;
    color: white;
    outline: none;
    border-radius: 15px;
    text-decoration: none;
    :hover{
        color: white;
        opacity: 0.9;
    }
`

export default function NotFound() {
    return (
        <Container className='d-flex align-items-center justify-content-center '>
            <Card className='d-flex flex-column-reverse flex-md-row align-items-center justify-content-center p-5'>
                <div>
                    <Heading>404</Heading>
                    <Text>Oops, Page Not Found!!!</Text>

                    <Button as={Link} to='/' className='px-4 py-2'>Go to Home</Button>
                </div>

                <div>
                    <img className='w-100' src={Image} alt="Error 404" />
                </div>
            </Card>
        </Container>
    )
}
