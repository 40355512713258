import { useState,useEffect, useContext } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "./contexts/authValidation";
import URLS from "./utils/app-url";
import NotFound from "./NotFoundPage/NotFound";
import PopUp from "./Components/popup/popup";
const ProtectedRoutes = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const contextProps = useContext(AuthContext);
    const navigator = useNavigate();
    const jwtValue = localStorage.getItem("jwt");
    const socialLoginValue = localStorage.getItem("socialToken");
    const [jwt, setJwt] = useState("");
    const [socialLogin, setSocialLogin] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const getAccessToken = async () => {
        try {
         //  debugger
            const idTokenClaims = await getAccessTokenSilently();
            localStorage.setItem("socialToken", idTokenClaims);

            if (localStorage.getItem("socialToken")) {
                try {
                    let data = JSON.stringify({
                        "auth0Token": localStorage.getItem("socialToken"),
                        "role": localStorage.getItem("socialRole"),
                        "ipAddress": sessionStorage.getItem('ip-address'),
                        "location": sessionStorage.getItem('user-country')
                    });

                    const apiResponse = await fetch(URLS.loginWithSocialMedia, { "method": 'POST', headers: { "Access-Control-Allow-Origin": '*', "Content-Type": "application/json" }, body: data })

                    const apiResponse2 = await apiResponse.json()

                    if (apiResponse2.status == "200") {
                        handleShowModal(`${apiResponse2.message}`);
                        localStorage.setItem(
                            "jwt",
                            `${apiResponse2?.data?.tokens?.access?.token}`
                        );
                       
                        setIsLoading(false);

                        if (apiResponse2?.data?.user?.role == "company" && apiResponse2?.data?.user?.profileComplete == true) {
                            return setNavigateLink("/companyDashboard")
                        } else if (apiResponse2?.data?.user?.role == "company" && apiResponse2?.data?.user?.profileComplete == false) {
                            return setNavigateLink("/createCompanyProfile"), handleShowModal( "Kindly complete your personal/company informations.")
                        }
                        else if (apiResponse2?.data?.user?.role == "user" && apiResponse2?.data?.user?.profileComplete == true) {
                            return setNavigateLink("/userDashboard")
                        } else if (apiResponse2?.data?.user?.role == "user" && apiResponse2?.data?.user?.profileComplete == false) {
                            return setNavigateLink("/createUserProfile"), handleShowModal( "Kindly Complete user Profile details")              
                        } else if (apiResponse2?.data?.user?.role == "driver" && apiResponse2?.data?.user?.profileComplete == true) {
                            return setNavigateLink("/driverDashboard")
                        } else if (apiResponse2?.data?.user?.role == "driver" && apiResponse2?.data?.user?.profileComplete == false) {
                            return setNavigateLink("/DriverProfileDetails"), handleShowModal( "Kindly Complete Driver Profile details")
                        }

                        return apiResponse2;
                    } else {
                        handleShowModal( `${apiResponse2.message}`);
                        setIsLoading(false);
                        return;
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            } else {
                handleShowModal( `Something went wrong Kindly Retry`);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
     //  debugger
        const fetchData = async () => {
            await getAccessToken();
            const jwtValue = localStorage.getItem("jwt");
            const socialLoginValue = localStorage.getItem("socialToken");
            setJwt(jwtValue);
            setSocialLogin(socialLoginValue);
            contextProps.getUserDetails();
        };

        fetchData();
    }, []);

    return jwtValue || isAuthenticated ? <Outlet/> : <NotFound />
};
export default ProtectedRoutes;
