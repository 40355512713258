import React, { useState, useEffect, Suspense, lazy, useContext } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import auth0Config from '../src/utils/auth0-config';
import { AuthContext } from './contexts/authValidation';
import MapContext from './contexts/MapContext';
import { getFirebaseToken, onForegroundMessage } from './firebase';
import Loader from './utils/Loader';
import LanguageContext from './contexts/language';
import GetProfileContext from './contexts/GetProfileContext';
import './App.css';
import "leaflet/dist/leaflet.css";
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet-defaulticon-compatibility';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";
// import ProtectedRoutes from "./ProtectedRoutes"
// import NotFound from './NotFoundPage/NotFound';
import Routings from './Routes';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import PopUp from './Components/popup/popup';
import CookieConsent from "react-cookie-consent";
import SessionTimeout from './SessionTimer';

function App() {
  const [showNotificationBanner, setShowNotificationBanner] = useState(typeof Notification !== 'undefined' && Notification.permission === 'default');
  let contextProps = useContext(AuthContext);

  useEffect(() => {
    const getYourCountry = () => {
      fetch('https://api.ipify.org?format=json')  
        .then((response) => response.json())
        .then((data) => {
          const userIP = data.ip;
          sessionStorage.setItem('ip-address',userIP);
          fetch(`https://ipapi.co/${userIP}/country/`)
            .then((response) => response.text())
            .then((country) => {
              sessionStorage.setItem('user-country',country)
            })
            .catch((error) => console.error('Error fetching country:', error));
          })
        .catch((error) => console.error('Error fetching IP:', error));
    }
    getYourCountry();
  }, []);

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        const { notification: { title, body } } = payload;
      })
      .catch(err => {
      });

  },[]);

  const handleGetFirebaseToken = () => {
    if (typeof Notification === 'undefined' || typeof navigator.serviceWorker === 'undefined') {
      console.error('This browser does not support notifications or service workers.');
      return;
    }

    try {
      getFirebaseToken()
        .then((firebaseToken) => {
          if (firebaseToken) {
            localStorage.setItem('firebaseToken', firebaseToken);
            setShowNotificationBanner(false);
          }
        })
        .catch((err) => console.error('An error occurred while retrieving firebase token: ', err));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    handleGetFirebaseToken()
  }, [])
  
  return (
    <Suspense fallback={<Loader />}>  
      <SessionTimeout/>
      <div className="App">
        <GetProfileContext>
          <LanguageContext>
            <MapContext >
              <Auth0Provider domain={auth0Config.domain} clientId={auth0Config.clientId} redirectUri={auth0Config.redirectUri} audience={auth0Config.audience} >
                <Routings/>
              </Auth0Provider>
            </MapContext>
          </LanguageContext>
        </GetProfileContext>
      </div>
      
      <CookieConsent
        location="bottom"
        enableDeclineButton={true}
        buttonText="Allow All"
        declineButtonText="Decline All Unnecessary Cookies"
        cookieName="frontend.logistic-world.com/"
        flipButtons={true}
        declineButtonStyle={{background: '#012732', color: 'white', fontSize: "15px", fontWeight: '600', border: 'none', outline: 'none', borderRadius: '7px', padding: '8px 13px'}}
        style={{ background: "white", color:'black', width: '395px', wordBreak: 'break-word', margin: `${window?.innerWidth < 767 ? 'none': '0px 0px 15px 15px' }`, borderRadius: '5px'}}
        buttonStyle={{ margin: '5px', color: "white", fontSize: "15px", fontWeight: '600', background: '#012732', borderRadius: '7px', padding: '8px 13px' }} >
        <p className='mb-0'>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. <Link to='/termsCondition'>learn more...</Link> </p>
      </CookieConsent> 

      {contextProps.showModal && ( <PopUp
        show={contextProps.showModal}
        handleClose={contextProps.handleCloseModal}
        navigateLink={contextProps.navigateLink}
        data={contextProps.modalData}
      /> )}
    </Suspense>
  );
}

export default App;
