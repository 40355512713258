import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from "./ProtectedRoutes"
import NotFound from './NotFoundPage/NotFound';
const Login = lazy(() => import('./Components/common/login'))
const SignUp = lazy(() => import('./Components/common/signUp'))
const DriverSignUp = lazy(() => import('./Components/common/DriverSignUp'))
const ForgotPassword = lazy(() => import('./Components/common/forgotPage'))
const Index = lazy(() => import('./Components/landingPage'))
const TermsConditions = lazy(() => import('./Components/landingPage/LandingComponents/TermsConditions'))
const AddUser = lazy(() => import('./Components/Test/UserManagement/AddUser/addUser'))
const UserManagement = lazy(() => import('./Components/UsersManagement/index'))
const CreateUserProfile = lazy(() => import('./Components/UsersManagement/UserProfile/CreateUserProfile'))
const UserPostJob = lazy(() => import('./Components/UsersManagement/UserProfile/UserPostJob'))
const UserPostDetails = lazy(() => import('./Components/UsersManagement/UserPost/UserPostDetails'))
const UserLastedPosting = lazy(() => import('./Components/landingPage/LandingComponents/UserPostDetails/UserLastedPosting'))
const UserLatestPostDescription = lazy(() => import('./Components/landingPage/LandingComponents/UserPostDetails/UserLatestPostDescription'))
const UserOnGoingBiddingDescription = lazy(() => import('./Components/landingPage/LandingComponents/UserPostDetails/UserOnGoingBiddingDescription'))
const AboutUs = lazy(()=> import('./Components/landingPage/LandingComponents/AboutUs'))
const ContactUs = lazy(()=>import('./Components/landingPage/LandingComponents/ContactUs'))
const Imprint = lazy(()=>import('./Components/landingPage/LandingComponents/Imprint'))
const CompanyLastedPosting = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyLastedBidList'))
const CompanyLastedBidList = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyLastedBidList'))
const CreatePost = lazy(() => import('./Components/landingPage/LandingComponents/createPost/CreatePost'))
const CreateProfile = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/CreateProfile'))
const UserProfileDetails = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserProfileDetails'))
const CreateComapnyProfile = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/CreateCompanyProfile'))
const CompanyProfileDetails = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/CompanyProfileDetails'))
const CompanyAccountDetails = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/CompanyAccountDetails'))

const CompanyDashboard = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/CompanyDashboard'))
const ViewBids = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/ViewBids'))
const PostDescription = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/PostDescription'))
const BidsSubmited = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/BidsSubmited'))
const CompanyBidPost = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyBidPost'))
const CompanyBidPost1 = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyBidPost1'))
const CompanyOntheWay = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyOntheWay'))
const UserDashboard = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserDashboard'))
const UserViewBids = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserViewBids'))
const UserDashboardMail = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserDashboardMail'))
const UserAcceptBid = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserAcceptBid'))
const UserFeedback = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserFeedback'))
const UserTransactions = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserTransactions'))
const CompanyLastedBidListWithoutToken = lazy(() => import('./Components/landingPage/LandingComponents/CompanyPostDetails/CompanyLastedBidWithoutToken'))
const UserDashboardThankYou = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserDashboardThankYou'))
const ViewAwardBidDetails = lazy(() => import('./Components/landingPage/LandingComponents/CompanyProfileManagement/viewAwardBidDetails'))
const UserManageSettings = lazy(() => import('./Components/landingPage/LandingComponents/UserProfileManagement/UserManageSettings'))
const PrivacyPolicy = lazy(()=> import('./Components/landingPage/LandingComponents/PrivacyPolicy'))
const CreateDriverProfile = lazy(() => import('./Components/landingPage/LandingComponents/DriverProfileManagement/CreateDriverProfile'))
const DriverProfileDetails = lazy(() => import('./Components/landingPage/LandingComponents/DriverProfileManagement/DriverProfileDetails'))
const DriverDashboard = lazy(() => import('./Components/landingPage/LandingComponents/DriverProfileManagement/DriverDashboard'))
const DriverAdminstrator = lazy(() => import('./Components/landingPage/LandingComponents/DriverProfileManagement/DriverAdminstrator'))
const DriverViewBids = lazy(() => import('./Components/landingPage/LandingComponents/DriverProfileManagement/DriverViewBids'))
const GoogleMapPage = lazy(() => import('./Components/landingPage/LandingComponents/Map/GoogleMap'))
const Notification = lazy(()=>import('./Components/landingPage/LandingComponents/Notification'))
const ChatSupport = lazy(() => import('./Components/common/ChatSupport'))
const FAQ = lazy(()=> import('./Components/landingPage/LandingComponents/FAQ'))

export default function Routings (){
    return(
        <Routes>
            <Route path='' element={<Navigate to={"/home"} />} />
            <Route path='/home' element={<Index />} />

            <Route path="/login" element={ <Login/>} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/driverSignUp" element={<DriverSignUp />} />
            <Route path="/forgotPage" element={<ForgotPassword />} />

            <Route path="/termsCondition" element={<TermsConditions />} />
            <Route path='/faq' element={<FAQ/>} />
            <Route path='/about-us' element={<AboutUs/>} />
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path='/imprint' element={<Imprint/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />

            <Route path="/userLastedPosting" element={<UserLastedPosting />} />
            <Route path="/comapanyLastedPosting" element={<CompanyLastedPosting />} />
            <Route path="/createPost" element={<CreatePost />} />
            <Route path="/userOnGoingBiddingDescription/:id" element={<UserOnGoingBiddingDescription />} />
            <Route path="/userLatestPostDescription" element={<UserLatestPostDescription />} />
            <Route path="/comapany-bid-post/:id" element={<CompanyBidPost1 />} />

            <Route path='' element={<ProtectedRoutes />}>
                <Route path="/addUser" element={<AddUser />} />
                <Route path="/userManagement" element={<UserManagement />} />
                <Route path="/userProfile" element={<CreateUserProfile />} />
                <Route path="/userPostJob" element={<UserPostJob />} />
                <Route path="/userPostDetails" element={<UserPostDetails />} />
                <Route path="/userDashboardThankyou" element={<UserDashboardThankYou />} />
                <Route path="/comapanyLastedBidlist" element={<CompanyLastedBidList />} />
                <Route path="/postedDetails" element={<CompanyLastedBidListWithoutToken />} />
                <Route path="/comapanyBidPost" element={<CompanyBidPost />} />
                <Route path="/comapanyOntheWay" element={<CompanyOntheWay />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/createUserProfile" element={<CreateProfile />} />
                <Route path="/userProfilesDetails" element={<UserProfileDetails />} />
                <Route path='/createCompanyProfile' element={<CreateComapnyProfile />} />
                <Route path="/companyProfileDetails" element={<CompanyProfileDetails />} />
                <Route path="/companyAccountDetails" element={<CompanyAccountDetails />} />
                <Route path="/companyDashboard" element={<CompanyDashboard />} />
                <Route path="/viewBids" element={<ViewBids />} />
                <Route path="/postDescription" element={<PostDescription />} />
                <Route path="/bidsSubmited" element={<BidsSubmited />} />
                <Route path="/viewAwardBidDetails" element={<ViewAwardBidDetails />} />
                <Route path="/userDashboard" element={<UserDashboard />} />
                <Route path="/userViewBids" element={<UserViewBids />} />
                <Route path="/userDashboardMail" element={<UserDashboardMail />} />
                <Route path="/userAcceptBid" element={<UserAcceptBid />} />
                <Route path="/userFeedback" element={<UserFeedback />} />
                <Route path="/userTransaction" element={<UserTransactions />} />
                <Route path="/userManageSettings" element={<UserManageSettings />} />
                <Route path="/createDriverProfile" element={<CreateDriverProfile />} />
                <Route path="/DriverProfileDetails" element={<DriverProfileDetails />} />
                <Route path="/driverDashboard" element={<DriverDashboard />} />
                <Route path="/driverAdminstrator" element={<DriverAdminstrator />} />
                <Route path="/driverViewBids" element={<DriverViewBids />} />
                <Route path="/googleMap" element={<GoogleMapPage />} />
                <Route path="/chat-support" element={<ChatSupport />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}