import React, { useEffect } from 'react';                         
import ReactDOM from 'react-dom/client';      
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  
import 'primereact/resources/primereact.css'; 
import 'primeicons/primeicons.css';
import './flags.css';
import AuthValidation from './contexts/authValidation';            
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react"; 
import { defaultLocale, dynamicActivate } from "./i18n.jsx"

const I18nApp = () => {

  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root')); 

root.render(
    <BrowserRouter>
      <AuthValidation>
        <I18nApp />
      </AuthValidation>
    </BrowserRouter>
);