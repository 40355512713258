import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PopUp from './Components/popup/popup';

const SessionTimeout = () => {
    const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes in seconds
    const location = useLocation();

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    useEffect(() => {
        const resetTimer = () => {
            setTimeLeft(1800);
        };

        const countdown = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime === 120) {
                    handleShowModal('You have 2 minutes left, kindly do something otherwise you will be logged out.');
                    setNavigateLink('');
                }
                  if (prevTime === 1) {
                    clearInterval(countdown);
                    handleShowModal('You are logged out, kindly login again.');
                    setNavigateLink('/login');
                    localStorage.clear();
                    return 0;
                  }
                  return prevTime - 1;
            });
        }, 1000);

        resetTimer();
        return () => clearInterval(countdown);
    }, [location]);

  return (
    <div>
        {showModal && ( <PopUp
            show={showModal}
            handleClose={handleCloseModal}
            navigateLink={navigateLink}
            data={modalData}
        /> )}
    </div>

  );
};

export default SessionTimeout;
