import React, {useState, useContext, createContext} from 'react'

export const LanguageContextHook = createContext();

export const useLanguageContextHook =()=>{
    return useContext(LanguageContextHook);
}

export default function LanguageContext({children}){
    const [language, setLanguage]= useState('En');

    const data = {
        language,
        setLanguage
    }
    return <LanguageContextHook.Provider value={{...data}}>
        {children}
    </LanguageContextHook.Provider>
}