import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './popup.css';
import { RxCross1 } from "react-icons/rx";
import axios from 'axios';
const OkButton = styled.button`
    border-radius: 46px;
    padding: 13px 30px;
    font-weight: 500;
    border: none;
    background: rgb(197, 216, 46);
    font-size: 18px;
    margin-bottom: 5px;
    color: rgb(4, 84, 107);
    transition: 0.5s;
    :hover{
        transition: 0.5s;
        opacity: 0.7;
    }
`
const CancelButton = styled.button`
    border: none;
    background: transparent;
    outline: none;
`
const Div = styled.div`
min-height: 3.3rem;
padding: 0 35px;
display: flex;
align-items: center;
font-size: 17px;
justify-content: center;
// font-weight: 500;
word-break: break-word;
`
const PopUp = ({ show, handleClose, navigateLink, data }) => {
    const navigate = useNavigate();
    const handleCloseFun = () =>{
        handleClose(false);
        if(navigateLink?.length > 0){
            navigate(navigateLink);
        }
    }
    const handleCancelFun =()=>{
        handleClose(false);
    }
    
    const handleSkip =()=>{
        handleClose(false);
        localStorage.setItem('isSkip', 'true');
    }
    return (
        <Modal show={show} className='response-modal' style={{backdropFilter: 'blur(6px)'}}>
            <Modal.Body style={{ color: 'white', backgroundColor: '#04546b', padding: '7px', borderRadius: '20px'}}>
                <div className='text-end mb-2 mt-2 me-2'   >
                   <button className='border-0 bg-transparent'onClick={()=>handleCancelFun()}> <RxCross1 color='white' size={22}/> </button>
                </div>
                <Div>
                    {data}
                    {/* {data.includes("have to verify your company first") ? data : "data"} */}
                </Div>
                    {data.includes("To be able to submit a bid") ? 
                    <div className='mt-2 d-flex justify-content-between align-items-center px-4 py-2'>
                        <button className='bg-transparent border-0 text-white text-decoration-underline' onClick={()=>handleSkip()}>skip now</button>
                        <OkButton onClick={()=>handleCloseFun()}>OK</OkButton>
                    </div> : 
                    <div className='mt-2 text-center px-4 py-2'>
                        <OkButton onClick={()=>handleCloseFun()}>OK</OkButton>
                    </div>
                    }
            </Modal.Body>
        </Modal>
    );
};

PopUp.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    navigateLink: PropTypes.string,
    data: PropTypes.any.isRequired, 
};

export default PopUp;