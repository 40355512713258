import { i18n, Messages } from "@lingui/core"

export const locales = {
    en: "English",
    es: "Español",
    de: "Deutsch"
}
export const defaultLocale = "en"

const catalogs = {
    en: async () => {
      const { messages } = await import(
        `./file.js!=!@lingui/loader!./locales/en/messages.po`
      )
      return messages
    },

    es: async () => {
      const { messages } = await import(
        `./file.js!=!@lingui/loader!./locales/es/messages.po`
      )
      return messages
    },

    de: async ()=>{
      const {messages} = await import(
        `./file.js!=!@lingui/loader!./locales/de/messages.po`
      )
      return messages
    }
}

export async function dynamicActivate(locale) {
    const messages = await catalogs[locale]()
    // i18n.loadAndActivate({ locale, messages })
    i18n.load({ [locale]: messages });
    i18n.activate(locale);
    localStorage.setItem('selectedLanguage', locale);
}