
const auth0Config = {
    domain: "logistics-bidding.us.auth0.com",
    clientId: "gazskcLmuQpl3Q33DAC3O69eXJOiC0Mh",
    audience: "https://logistics-bidding.us.auth0.com/api/v2/",
    redirectUri: "https://frontend.logistic-world.com/userDashboard",
    responseType: 'token id_token',
    scope: 'openid profile email',
};

export default auth0Config;