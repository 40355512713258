import React from 'react'
// import '../css/Loader.css'
import "./loader.css"
import LogisticLoader from "../assets/logisticLoader.gif"
export default function Loader() {
    return (
        <div className='d-flex justify-content-center align-items-center w-100 main-loader'>
           <img width={200} src={LogisticLoader} alt="LogisticLoader" />
        </div>
    )
}