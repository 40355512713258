// import { initializeApp } from 'firebase/app';
// import { getToken, getMessaging, onMessage } from 'firebase/messaging';

// const firebaseConfig = {
//   apiKey: 'AIzaSyBXes7H2O30aQusKc9B2T9wXReNPS2qs0I',
//   authDomain: 'logistics-react.firebaseapp.com',
//   projectId: 'logistics-react',
//   storageBucket: 'logistics-react.appspot.com',
//   messagingSenderId: '523110252654',
//   appId: '1:523110252654:web:f19e8b295ece347792267f'
// };

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// export const getOrRegisterServiceWorker = () => {
//   if ('serviceWorker' in navigator) {
//     return window.navigator.serviceWorker
//       .getRegistration('/firebase-push-notification-scope')
//       .then((serviceWorker) => {
//         if (serviceWorker) return serviceWorker;
//         return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
//           scope: '/firebase-push-notification-scope',
//         });
//       });
//   }
//   throw new Error('The browser doesn`t support service worker.');
// };

// export const getFirebaseToken = () =>
//   getOrRegisterServiceWorker()
//     .then((serviceWorkerRegistration) =>
//       getToken(messaging, { vapidKey: 'BKiffP6C6JV6EAwMLzn5CnTdWYDrFLfdQFGPyUoqNYkkPDHDSOjjadiYC9V5PR2L0nQ0ryp5XtSNJUeSu4NGIEg', serviceWorkerRegistration }));

// export const onForegroundMessage = () =>
//   new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));




import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBXes7H2O30aQusKc9B2T9wXReNPS2qs0I',
  authDomain: 'logistics-react.firebaseapp.com',
  projectId: 'logistics-react',
  storageBucket: 'logistics-react.appspot.com',
  messagingSenderId: '523110252654',
  appId: '1:523110252654:web:f19e8b295ece347792267f'
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  return Promise.reject(new Error('The browser does not support service workers.'));
};

export const requestNotificationPermission = () => {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      return Promise.resolve('granted');
    } else if (Notification.permission !== 'denied') {
      return Notification.requestPermission();
    } else {
      return Promise.reject(new Error('Notification permission denied'));
    }
  } else {
    return Promise.reject(new Error('Notifications are not supported in this browser.'));
  }
};

export const getFirebaseToken = () =>
  requestNotificationPermission()
    .then((permission) => {
      if (permission === 'granted') {
        return getOrRegisterServiceWorker()
          .then((serviceWorkerRegistration) =>
            getToken(messaging, {
              vapidKey: 'BKiffP6C6JV6EAwMLzn5CnTdWYDrFLfdQFGPyUoqNYkkPDHDSOjjadiYC9V5PR2L0nQ0ryp5XtSNJUeSu4NGIEg',
              serviceWorkerRegistration
            })
          );
      } else {
        throw new Error('Notification permission not granted');
      }
    });

export const onForegroundMessage = () =>
new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));